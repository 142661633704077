@import '../../../base/assets/scss/_base/config';

.recently-played-games {
  font-family: var(--font-family-heading);
  margin-bottom: 20px;
  text-align: left;


  &__title {
    display: inline-flex;
    text-transform: capitalize;
    margin: 30px 8px 12px 0;
    color: $batcave;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    letter-spacing: -0.6px;
    position: relative;
    &::before{
      content: '';
      height: 32px;
      background-color: $pineapple;
      width: 4px;
      border-radius: 4px;
      margin: 0px 8px 0px 0px;
    }
  }

  .recently-played-games-tray {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0 -6px 0;

    .recently-played-games-tray-item {
      $marginWidth:  12px;
      border-radius: 4px;
      overflow: hidden;
      margin: 0 6px $marginWidth;
      padding: 0;
      width: calc(20% - $marginWidth);


      @media screen and (max-width: 1332px) {
        width: calc(33% - $marginWidth);
      }

      @media screen and (max-width: 1180px) {
        width: calc(50% - $marginWidth);
      }

      @media screen and (max-width: 900px) {
        width: 100%;
      }

      .recently-played-image-wrap {
        position: relative;
        width: 100%;

        .games-link-overlay {
          background: rgba(0, 0, 0, .8);
          display: block;
          height: 100%;
          left: 0;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          top: 0;
          transition: opacity .2s $ease;
          width: 100%;
          z-index: 20;
          border-radius: 16px
        }

        .games-link-overlay:hover {
          opacity: 1;
        }

        img {
          display: block;
          height: auto;
          width: 100%;
          border-radius: 12px;
        }

        a {
          color: #333;
        }

        .recently-played-games-text-wrap {
          button {
            background: $brand-games;
            border: none;
            color: #fff;
            font-size: 13px;
            left: 50%;
            letter-spacing: 1px;
            line-height: 1.7em;
            padding: 10px 30px;
            position: absolute;
            text-align: center;
            text-transform: capitalize;
            top: 50%;
            transform: translate(-50%, -50%);
            white-space: nowrap;
            border-radius: 48px;
            font-weight: 600;
          }

          button:hover {
            background: $brand-games-hover;
          }
        }
      }
    }
  }
}
